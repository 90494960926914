#dashboard .digilean-widget {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.digilean-widget {
    background: var(--digilean-primary-background);/*white;*/
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.digilean-widget .widget-component {
    height: inherit;
    padding: 0px;
    margin-bottom: 0px;
    overflow: hidden; 
}
.digilean-widget .widget-component-container {
    height: 100%;
}

.digilean-widget .widget-component-container-scrollable {
    height: 100%;
    overflow-y: auto;
}

.digilean-widget .widget-component .ibox:first-child {
    height: 100%;
}

.digilean-widget .ibox, .digilean-widget .ibox-title {
    margin-bottom: 0px;
    border-style: none;
}

.digilean-widget .widget-content {
    flex: 1 1 auto;
    overflow: visible;
}

.widget-header {
    color: white;
    background-color: #0099DE;
    background-color: #34B3E4;
    border-color: #0077B5;
    cursor: move;
}

.widget-header .add-app-button:hover {
    color: #0099DE;
    background: white;
}

.exit-fullscreen {
    flex: 0 0 2rem;
    z-index: 1001;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: end;
    margin: 0;
    border: none;
    background: none;
    div.button-wrapper {
        padding: 1rem;
    }
}

.widget-menu-transclude {
    display: inline-block;
}

div.debug-mode {
    display: flex;
    flex-direction: column;
    height: 100%;
}
div.debug-mode digilean-json-editor {
    flex: 1 1 auto;
}
div.debug-info {
    position: absolute;
    left: 0.5rem;
    top: 3rem;
}