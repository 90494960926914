registration, registration .app {
    display: block;
    height: 100%;
}

registration input.form-control {
    height: 2.5em;
    font-size: 1em;
}
registration select.form-control {
    height: 2.5em;
    font-size: 1em;
}

registration .time-adjustment {
    height: 20px;
}

registration .timepicker-adjustment {
    margin-top: -40px;
}

registration .scroll-app {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 0%;
}